import './SearchComponent.css'

export default function Search_Component({title,handleSearchInput}) {


    return(
        <div className="d-flex padding-episodes container-title-ep">
            <h2 className="title-episode  text-uppercase">{title}</h2>
            <form className="position-relative form-search" onSubmit={(event)=>event.preventDefault()}>
                <button className="border-0 bg-transparent position-absolute z-1 img-search" ><img  src="./images/search-icon.svg" alt="search" /></button>
                <input type="text" id="search" className="border bg-transparent rounded-3 border-white  text-white" onKeyUp={(event)=> handleSearchInput(event)} onInput={(event)=> handleSearchInput(event)}/>
            </form>            
        </div>
    )
}