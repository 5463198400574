 const thanksList=[{
    id:1,
    name:"Emma Camilleri",
    message:"Co-founder",
    linkedin:"https://www.linkedin.com/in/emma-camilleri-666a5a185/"
},{
    id:2,
    name:"Maria Eugenia Lopez ",
    message:"CEO of Designs",
    linkedin:"https://www.linkedin.com/in/maria-eugenia-lopez-b1998111a/"
},{
    id:3,
    name:"María Victoria Lopez",
    message:"Website Wonder",
    linkedin:"https://www.linkedin.com/in/m-victoria-lopez/"
},{
    id:4,
    name:"Daniel Paigge",
    message:"Director of Jingles",
    linkedin:"https://www.linkedin.com/in/daniel-paigge-5a3a83116/"
},{
    id:5,
    name:"Jelle Clerc ",
    message:"Logo Guru",
    linkedin:"ttps://www.linkedin.com/in/jelle-clerc/"
}]
export default thanksList;