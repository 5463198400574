import { Fragment ,useState} from "react";

import Nav from '../../components/Nav';
import Hero from '../../components/Hero';
import EpisodesHome from '../../components/EpisodesHome';
import Listening from '../../components/Listening';

import './Home.css'

export default function Home() {
    let [hiddenModal,setHiddenModal]=useState(true);
    let [dataEp,setDataEp]=useState({})


    return(
        <Fragment>
            { hiddenModal === false ? <Listening setHiddenModal={setHiddenModal} dataEp={dataEp}/> :''}
            <img className="position-fixed start-0 img-bg" src="./bg-left.png" alt=""/>
            <img className="w-100 position-absolute start-0 img-bg-mobile" src="./bg-mobile.png" alt=""/>
            <Nav />
            <div className="margin-home">
                <Hero/>
{                <EpisodesHome setHiddenModal={setHiddenModal} setDataEp={setDataEp}/>            
}            </div>
       
        </Fragment>
    )
}