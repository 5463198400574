import { useEffect, useState } from 'react';
import './Listening.css';
import descAndImageEp from '../../info/descrip&imageEp';

export default function Listening({setHiddenModal,dataEp}) {

    const[addClass,setAddClass]=useState('appearClass');
    const[addClass1,setAddClass1]=useState('');
    const[description,setDescription]=useState("")

    const disappear=()=>{
        setTimeout(()=> setHiddenModal(true),2000); 
        setAddClass("disappearClass")
        setTimeout(()=>setAddClass1(""),5)
    }
useEffect(()=>{

    let numEp=dataEp.snippet.title.split(" ")[1];
    let resultDesc= descAndImageEp.find((rss)=>{return rss.title.includes(numEp) === true});
    if(resultDesc !== undefined){
        setDescription(resultDesc.content)
    }else{
        setDescription(` Join the digital revolution and never miss an episode of "Untangling Web3" by subscribing to our podcast with your email. We break down the complex world of Web3 into digestible episodes, providing clear insights and crucial information to help you stay ahead. Join the digital revolution and never miss an episode of "Untangling Web3" by subscribing to our podcast with your email. We break down the complex world of Web3 into digestible episodes, providing clear insights and crucial information to help you stay ahead.Join the digital revolution and never miss an episode of "Untangling Web3" by subscribing to our podcast with your email. We break down the complex world of Web3 into digestible episodes, providing clear insights and crucial information to help you stay head.Join the digital revolution and never miss an episode of "Untangling Web3" by subscribing to our podcast with your email. We break down the complex world of Web3 into digestible episodes, providing clear insights and rucial information to help you stay ahead.Join the digital revolution and never miss an episode of "Untangling Web3" by subscribing to our podcast with your email. We break down the complex world of Web3 into digestible episodes, providing clear insights and crucial information to help you stay ahead.Join the digital revolution and never miss an episode of Untangling Web3" by subscribing to our podcast with your email. We break down the complex world of Web3 into digestible episodes, providing clear insights and crucial information to help you stay ahead.Join the digital revolution and never miss an episode of "Untangling Web3" by subscribing to our podcast with your email. We break down the complex world of Web3 into digestible episodes, providing clear insights and crucial information to help you stay ahead.`)
    }
    
setTimeout(()=>{setAddClass1("background-img-static")},500)
},[])
    return(
        <div className={"background-static "+ addClass1}>       
            <section id="listening" className={"container-fluid modal-style "+addClass} >
                <div className="row justify-content-end border-bottom-0">
                    <button type="button" className="btn-x p-0" onClick={()=>disappear()} >
                        <img src="./images/icon-x-modal.svg" alt='button x'/>
                    </button>
                </div>        
                <div className=' d-flex episode-info justify-content-center gap-48'>
                    <iframe src={'https://www.youtube.com/embed/'+dataEp.id.videoId} title='videoUW' ></iframe>

                    <div className='section-info-ep'>
                        <p className='episode-number'>{"EPISODE "+dataEp.snippet.title.split(" ")[1] }</p>
                        <h2 className='title-ep-rep'>{dataEp.snippet.title}</h2>

                        <p className='date-time-ep'><span>{dataEp.snippet.publishTime.split("T")[0]}</span>. <span>5 min read</span></p>
                        <p className='desc-ep'>
                        {description}</p>
                    </div>
            
                </div>
            </section>
        </div>

    )
}