
import{ useEffect ,useState} from 'react';
import descAndImageEp from '../../info/descrip&imageEp';
import './CardPageEpisode.css'

export default function Card_Episodes_Page({setHiddenModal,setDataEp,ep,listEp}) {
    const[rssInfo,setRssInfo]=useState({image:"https://media.rss.com/untanglingweb3/ep_cover_20240105_020116_e45d8f64730fc1ea6f6b9a54da0a6215.jpg"});
    const[titleEp,setTitleEp]=useState('');

    useEffect(()=>{

        const epNumber= ep.snippet.title.split(" ")[1]
        let dataRss=descAndImageEp.find((rss)=>{return rss.title.includes(epNumber+" ") === true});
        if(dataRss !== undefined){
            setRssInfo(dataRss)
        }else{
            setRssInfo({image:"https://media.rss.com/untanglingweb3/ep_cover_20240105_020116_e45d8f64730fc1ea6f6b9a54da0a6215.jpg"})
        }
        // if(ep.snippet.title == ""){
        //     setTitleEp(" The Bitcoin ETF Approvals ");
        //     setRssInfo({image:'https://media.rss.com/untanglingweb3/ep_cover_20240114_040137_85d8a8f14f11ea170a9d18030e928be2.jpg'
        // })
        // }else{
            let firstSplit=ep.snippet.title.split("|")[0].split(" ");
            let title=firstSplit.filter((item)=>item !== "UW3" && item.includes("#") ===false && item !== "-" && item !== "Untangling:")
            setTitleEp(title.join(" "))
       // }

      
    },[listEp])

    return(
        <div className="episode bg-black rounded-4">
            <div className='padding-card border-bottom border-white d-flex episode-info-page'>
            <div className="position-relative container-rep" onClick={()=>{setHiddenModal(false); setDataEp(ep)}}>
                <img className='img-untangling' src={rssInfo.image}  alt="logo-uw3"/>
                <button className="border-0 d-none position-absolute w-100 h-100 top-0 start-0 btn-play">
                    <img src="./images/play-btn.png" alt="play button"/>
                </button>
            </div>
                <div className='d-flex flex-column justify-content-center'>
                    <h3 className='title-card'>{titleEp}</h3>
                    <p className='date-episode'>{ep.snippet.publishTime.split("T")[0]}</p>
                    <p className='text-uppercase episode-number'>{"episode "+ep.snippet.title.split(" ")[1]} </p>
                </div>
            </div>
            <div className='padding-card streaming-icon-section d-flex justify-content-start'>
                <a href='https://open.spotify.com/show/0rFpzpbYrCmE50e82uilue'>
                    <img className='icon-episodes' src='./images/episode-cards/spotify-icon.png' alt='spotify icon'/>
                </a>
                <a  href='https://podcasts.apple.com/gb/podcast/untangling-web3/id1691703362'>
                    <img className='icon-episodes' src='./images/episode-cards/Subtract.png' alt='icon'/>
                </a>
                <a  href='https://www.youtube.com/@UntanglingWeb3'>
                    <img className='icon-episodes' src='./images/episode-cards/youtube-icon.png' alt='youtube icon'/>
                </a>
            </div>
           
        </div>
    )
}
 