import { useState } from 'react';
import './Subscribe.css';

export default function Subscribe({setHiddenModal}) {

    const [messageOk,setMessageOk]=useState(false);
    const[addClass,setAddClass]=useState('appearClass')

    const handleSubmit=async (event)=>{
        event.preventDefault();
        const form= new FormData(event.target);
       const response=await fetch("https://formspree.io/f/mnqklkzj",{
        method:"POST",
        body:form,
        headers:{
            'Accept':'application/json'
        }
       })

       if(response.ok){
            setMessageOk(true)
           setTimeout(()=>{event.target.reset();setMessageOk(false)},2000)
       }


    }
    const disappear=()=>{
        setTimeout(()=> setHiddenModal(true),2000); 
            setAddClass("disappearClass")

    }

    return(
        <section id="subscribe" className={"container-fluid modal-style "+addClass} >
            <div className="row justify-content-end border-bottom-0">
                <button type="button" className="btn-x p-0" onClick={()=>disappear()} >
                    <img className='w-100' src="./images/icon-x-modal.svg" alt='button x'/>
                </button>
            </div>        
        <div className='row justify-content-center w-100 full-height align-items-center'>
        {messageOk === false ? 
            <div className='w-723 pb-diference'>
                <h2 className="row subscribe-title">Subscribe</h2>
                <p className='text-subscribe'>Support us by subscribing to get each new episode first, plus access to exclusive bonus content and insights.</p>
                <form className="row " onSubmit={(event)=> handleSubmit(event)} method='POST'>
                    <div className="col-lg-12 form-floating my-32 form-subscribe">
                        <input id="subscribe-input" className="form-control bg-transparent w-100 subsForm" type="email" placeholder="Share your email" name='email'/>
                        <label className="form-label ps-5 subsForm fw-normal" >Share your email</label>
                    </div>
                    <input className="col-lg-12 d-flex justify-content-center align-items-center button-form text-uppercase text-center " type="submit" value="SUBSCRIBE" />
                </form>  
            </div>
            :
                <div className="container d-flex flex-column align-items-center mt-4 messageOk justify-content-center w-50 section-send" onClick={()=>setMessageOk(false)}>

                    <h2 className="row success-subscribe-title">Successfully subscribe!</h2>
                    <p className='row text-ok'> You've been added to our newsletter</p>
                </div>
                }      
            </div>
        </section>
    )
}