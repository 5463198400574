import { Fragment,useState,useEffect} from "react";

import Nav from '../../components/Nav';
import Card from "../../components/CardEpisodePage";
import SearchComponente from '../../components/SearchComponent';
import Listening from '../../components/Listening';

import './Episodes.css'

export default function EpisodesPage() {
    let [hiddenModal,setHiddenModal]=useState(true);
    let [searchEmpty,setSearchEmpty]=useState(false);
    let[allEp,setAllEp]=useState({});
    let [dataEp,setDataEp]=useState({
        snippet:{
           "publishedAt": "",
           "channelId": "",
           "title": "",
           "description": "",
           "channelTitle": "Untangling Web3",
           "liveBroadcastContent": "none",
           "publishTime": ""
       }
     })
   
    let [listEp,setListEp]=useState([{
        snippet:{
           "publishedAt": "",
           "channelId": "",
           "title": "",
           "description": "",
           "channelTitle": "Untangling Web3",
           "liveBroadcastContent": "none",
           "publishTime": ""
       }
     }]);
    let counter=0;



      const handleSearchInput=(event)=>{
        let searchInput=event.target.value.toLowerCase();   
        
        const searchEp= allEp.filter((pal)=>{ 
            let word=pal.snippet.title.toLowerCase();
            return word.includes(searchInput) })

            if(searchEp.length >0){
                setListEp(searchEp)
            }
            if(searchInput === ""){
                setSearchEmpty(true)
            }else{
                setSearchEmpty(false)
            }
      }
    useEffect(()=>{
        let data=JSON.parse(localStorage.getItem("data"));     
        setListEp(data);
        setAllEp(data)
       
    },[])
    return(
        <Fragment>
        { hiddenModal === false ? <Listening setHiddenModal={setHiddenModal} dataEp={dataEp}/> :''} 
            <img className="position-fixed start-0 img-secondary" src="./bg-secondary.png" alt="" aria-hidden="true"/>
            <img className="w-100 position-absolute start-0 img-bg-mobile" src="./bg-mobile.png" alt="" aria-hidden="true"/>
            
            <Nav reference="episodes"/>
            <SearchComponente title="episode archive"  handleSearchInput={handleSearchInput} />
            <section className="padding-episodes d-flex flex-wrap section-episodes-page section-list-episodes">
            {listEp.map((ep)=>{
                counter++
                return <Card setHiddenModal={setHiddenModal} key={counter} setDataEp={setDataEp} ep={ep} listEp={listEp} searchEmpty={searchEmpty}/>
            })}
            </section>

        </Fragment>
    )
}