const hosts=[
    {
    id:1,
    image:'./images/about-img/alec.png',
    nameHost:'Alec Burns',
    textHost:[`Alec is an accomplished expert in Robotics, Computer Science, and Web3, with an Aerospace Engineering degree and a PhD from the University of Liverpool. He has two years of experience as a Web3 researcher and product specialist, combining deep tech knowledge with a passion for decentralized digital technologies. Alec's insights are shaped by his diverse expertise and his drive to explore new frontiers.
    `],
    linkedinHost:'https://www.linkedin.com/in/alecjohnburns/',
    orientation:"justify-content-end",
    textOrientatio:"text-end",
    classHost:'',
    idHost:'alec'
    
},{
    id:2,
    image:'./images/about-img/jack.png',
    nameHost:'Jack Davies',
    textHost:[`Jack, our resident Web3 native, is a technology strategist specializing in blockchain. Coming equipped with 5 years’ experience in R&D, Jack brings a unique perspective on the evolving digital landscape and its impact on society
   `],
    linkedinHost:'https://www.linkedin.com/in/jackowendavies/', 
    orientation:"justify-content-start",
    textOrientatio:"text-start",
    classHost:"reverse-location",
    idHost:'jack'
}]
export default hosts