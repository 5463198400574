import { Fragment ,useEffect}from "react";
import "./CardEpisode.css";
import { useState } from "react";
import descAndImageEp from '../../info/descrip&imageEp';

export default function Card_EpisodesHome({setHiddenModal,data,cant,limit,setDataEp,cantEp}) {
    const [title,setTitle]=useState('');
    const[rssInfo,setRssInfo]=useState({image:"https://media.rss.com/untanglingweb3/ep_cover_20240105_020116_e45d8f64730fc1ea6f6b9a54da0a6215.jpg"});
    const[dropwon,setDropDown]=useState("");
    const[cardCreated,setcCrdCreated]=useState(false)
    // let date;
    // let year=["January", "February","March"  ,"April","May","June","July"  ,"August" ,"September" ,"October" ,"November" ,"December"  ];

    // useEffect(()=>{
    //     let info=data.snippet.publishTime.split("T")[0];
    //     info=info.split("-")
    //     let month=info[1].split('')
    //     month.shift();
    //     console.log(month)
    //     date=info[2]+","+year[month]
    // },[])

  
    const showDescription=()=>{
        if(dropwon === "open"){
            setDropDown("")
        }else{
            setDropDown("open")
        }
        
    }

    const reformName=(podcastTitle)=>{
        let firstSplit=podcastTitle.split("|")[0];
                const titleChange=firstSplit.split(" ");

                let result=titleChange.filter((item)=> item !== "UW3" && item !== "Untangling:" && item !== '-'&& item !== "&amp;");
        
                let dataRss=descAndImageEp.find((rss)=>{return rss.title.includes(data.snippet.title.split(" ")[1]+" ") === true});
           
                if(dataRss!== undefined){
                        setRssInfo(dataRss)            
                }

                let resultFinal=result.filter((item)=> item.includes("#") === false)
                setTitle(resultFinal.join(" ")) 
    }


    useEffect(()=>{
        setTitle(data.snippet.title);
        reformName(data.snippet.title)
        console.log(data.snippet.title)
 
       
    },[cantEp,cardCreated])

    useEffect(()=>{
        setRssInfo({image:"https://media.rss.com/untanglingweb3/ep_cover_20240105_020116_e45d8f64730fc1ea6f6b9a54da0a6215.jpg"});
        setTitle(data.snippet.title);
        reformName(title)
        setcCrdCreated(!cardCreated)
       
    },[])

    return(
        
        <Fragment>
        { limit>cant?
        <div className="card-dropdown w-100 bg-black" >    
            <div className=" d-flex w-100 justify-content-between align-items-center card-section "> 
                
                    <div className="d-flex  aling-items-center gap-40 width-section" onClick={()=>{setDataEp(data);setHiddenModal(false)}}>
                        <div className="position-relative container-rep"  >
                            <img className="img-untangling-home" src={rssInfo.image} alt="logo-uw3"/>
                            <button className="border-0 d-none position-absolute w-100 h-100 top-0 start-0 btn-play">
                                <img src="./images/play-btn.png" alt="play button"/>
                            </button>
                        </div>
                        
                        <div className="w-75 d-flex flex-column justify-content-between">
                            <h3>{title}</h3>
                            <p>{data.snippet.publishTime.split("T")[0]}</p>
                            <div className="d-flex episode-social">
                                <div className='padding-card streaming-icon-section  justify-content-start ps-0'>
                                    <a href='https://open.spotify.com/show/0rFpzpbYrCmE50e82uilue'>
                                        <img className='icon-episodes' src='./images/episode-cards/spotify-icon.png' alt='spotify icon'/>
                                    </a>
                                    <a  href='https://podcasts.apple.com/gb/podcast/untangling-web3/id1691703362'>
                                        <img className='icon-episodes' src='./images/episode-cards/Subtract.png' alt='icon'/>
                                    </a>
                                    <a  href='https://www.youtube.com/@UntanglingWeb3'>
                                        <img className='icon-episodes' src='./images/episode-cards/youtube-icon.png' alt='youtube icon'/>
                                    </a>
                                </div>
                                <p className="episode-number">EPISODE <span>{data.snippet.title.split(" ")[1]}</span></p>                    
                            </div>

                        </div>
                    </div>
                    <div className='padding-card streaming-icon-section-mb justify-content-start ps-0'>
                        <a href='https://open.spotify.com/show/0rFpzpbYrCmE50e82uilue'>
                            <img className='icon-episodes' src='./images/episode-cards/spotify-icon.png' alt='spotify icon'/>
                        </a>
                        <a  href='https://podcasts.apple.com/gb/podcast/untangling-web3/id1691703362'>
                            <img className='icon-episodes' src='./images/episode-cards/Subtract.png' alt='icon'/>
                        </a>
                        <a  href='https://www.youtube.com/@UntanglingWeb3'>
                            <img className='icon-episodes' src='./images/episode-cards/youtube-icon.png' alt='youtube icon'/>
                        </a>
                    </div>

                    <img  src="./images/arrow-down.svg" className={"arrow-down "+ dropwon} alt="" onClick={showDescription}/>
            </div> 
            {dropwon === "open"? 
                <div className="description-ep-home border-top border-white">
                    <h3 className="title-desc-home">About this episode</h3>
                    <p className="desc-ep-home">{data.snippet.description}</p>
                </div>  :""
            }  
        </div>    
      :""}
        </Fragment>
    )
}
 