let infoAlt=[
  {
    "kind": "youtube#searchListResponse",
    "etag": "JWA-nZ_Y-uKL1s72KK5Tl6JEtt0",
    "regionCode": "AR",
    "pageInfo": {
      "totalResults": 38,
      "resultsPerPage": 37
    },
    "items": [ {
      "kind": "youtube#searchResult",
      "etag": "McKjC2BIS9wRXlNT-km_s-Doesg",
      "id": {
        "kind": "youtube#video",
        "videoId": "DMu-n78abvs"
      },
      "snippet": {
        "publishedAt": "2024-01-31T07:00:05Z",
        "channelId": "UC4No3izdi6Mx8HowouUNzww",
        "title": "UW3 #36 - Untangling: Regulated Web3 Finance with Stefan Kimmel | The Untangling Web3 Podcast",
        "description": "In this episode Alec and Jack welcome Stefan Kimmel, CEO of M2, a Web3 trading and investment platform. Kimmel discusses his ...",
        "thumbnails": {
          "default": {
            "url": "https://i.ytimg.com/vi/DMu-n78abvs/default.jpg",
            "width": 120,
            "height": 90
          },
          "medium": {
            "url": "https://i.ytimg.com/vi/DMu-n78abvs/mqdefault.jpg",
            "width": 320,
            "height": 180
          },
          "high": {
            "url": "https://i.ytimg.com/vi/DMu-n78abvs/hqdefault.jpg",
            "width": 480,
            "height": 360
          }
        },
        "channelTitle": "Untangling Web3",
        "liveBroadcastContent": "none",
        "publishTime": "2024-01-31T07:00:05Z"
      }
    }, {
      "kind": "youtube#searchResult",
      "etag": "mrhg9AFGGqbv1cSHTjHuNcODJQU",
      "id": {
        "kind": "youtube#video",
        "videoId": "uRhn9EuCjAk"
      },
      "snippet": {
        "publishedAt": "2024-01-24T07:30:20Z",
        "channelId": "UC4No3izdi6Mx8HowouUNzww",
        "title": "UW3 #35 - Untangling: The Philosophy of Web3 with Dr. Alex Cahana | The Untangling Web3 Podcast",
        "description": "In this episode, Alec and Jack are joined by Dr. Alex Cahana to discuss the philosophical side of Web3. Dr, Cahana, with over 25 ...",
        "thumbnails": {
          "default": {
            "url": "https://i.ytimg.com/vi/uRhn9EuCjAk/default.jpg",
            "width": 120,
            "height": 90
          },
          "medium": {
            "url": "https://i.ytimg.com/vi/uRhn9EuCjAk/mqdefault.jpg",
            "width": 320,
            "height": 180
          },
          "high": {
            "url": "https://i.ytimg.com/vi/uRhn9EuCjAk/hqdefault.jpg",
            "width": 480,
            "height": 360
          }
        },
        "channelTitle": "Untangling Web3",
        "liveBroadcastContent": "none",
        "publishTime": "2024-01-24T07:30:20Z"
      }
    },
      {
        "kind": "youtube#searchResult",
        "etag": "Mlbymdy_kjcEByYbMDk4KpsHSG8",
        "id": {
          "kind": "youtube#video",
          "videoId": "lfTk9ESdz-A"
        },
        "snippet": {
          "publishedAt": "2024-01-15T13:39:02Z",
          "channelId": "UC4No3izdi6Mx8HowouUNzww",
          "title": "UW3 #34 - Untangling: The Bitcoin ETF Approvals | The Untangling Web3 Podcast",
          "description": "The latest episode of Untangling Web 3 focuses on the recent approval of Bitcoin spot ETFs and its implications for the crypto and ...",
          "thumbnails": {
            "default": {
              "url": "https://i.ytimg.com/vi/lfTk9ESdz-A/default_live.jpg",
              "width": 120,
              "height": 90
            },
            "medium": {
              "url": "https://i.ytimg.com/vi/lfTk9ESdz-A/mqdefault_live.jpg",
              "width": 320,
              "height": 180
            },
            "high": {
              "url": "https://i.ytimg.com/vi/lfTk9ESdz-A/hqdefault_live.jpg",
              "width": 480,
              "height": 360
            }
          },
          "channelTitle": "Untangling Web3",
          "liveBroadcastContent": "upcoming",
          "publishTime": "2024-01-15T13:39:02Z"
        }
      },
      {
        "kind": "youtube#searchResult",
        "etag": "-kVEd6N_I_WbeizGE_1j7DuUEOE",
        "id": {
          "kind": "youtube#video",
          "videoId": "bTFWliUlUiQ"
        },
        "snippet": {
          "publishedAt": "2024-01-10T06:00:15Z",
          "channelId": "UC4No3izdi6Mx8HowouUNzww",
          "title": "UW3 #33 - Untangling: Tokenized Securities with Julian Kwan | Untangling Web3",
          "description": "In this episode of Untangling Web3, hosts Alec Burns and Jack Davis welcome Julian Kwan, CEO of InvestaX and IXSwap, ...",
          "thumbnails": {
            "default": {
              "url": "https://i.ytimg.com/vi/bTFWliUlUiQ/default.jpg",
              "width": 120,
              "height": 90
            },
            "medium": {
              "url": "https://i.ytimg.com/vi/bTFWliUlUiQ/mqdefault.jpg",
              "width": 320,
              "height": 180
            },
            "high": {
              "url": "https://i.ytimg.com/vi/bTFWliUlUiQ/hqdefault.jpg",
              "width": 480,
              "height": 360
            }
          },
          "channelTitle": "Untangling Web3",
          "liveBroadcastContent": "none",
          "publishTime": "2024-01-10T06:00:15Z"
        }
      },
      {
        "kind": "youtube#searchResult",
        "etag": "fJuo7dOrO1gSNgayBOzg7c17X0k",
        "id": {
          "kind": "youtube#video",
          "videoId": "AER4jBV_BS0"
        },
        "snippet": {
          "publishedAt": "2024-01-03T18:00:23Z",
          "channelId": "UC4No3izdi6Mx8HowouUNzww",
          "title": "UW3 #32 - Untangling: 2024 Predictions | The Untangling Web3 Podcast",
          "description": "In the latest episode of the podcast, the hosts embark on an explorative journey into key predictions for the Web3 space in 2024, ...",
          "thumbnails": {
            "default": {
              "url": "https://i.ytimg.com/vi/AER4jBV_BS0/default.jpg",
              "width": 120,
              "height": 90
            },
            "medium": {
              "url": "https://i.ytimg.com/vi/AER4jBV_BS0/mqdefault.jpg",
              "width": 320,
              "height": 180
            },
            "high": {
              "url": "https://i.ytimg.com/vi/AER4jBV_BS0/hqdefault.jpg",
              "width": 480,
              "height": 360
            }
          },
          "channelTitle": "Untangling Web3",
          "liveBroadcastContent": "none",
          "publishTime": "2024-01-03T18:00:23Z"
        }
      },
      {
        "kind": "youtube#searchResult",
        "etag": "j0D8aaZBXec24gzs0Ad5hZiCT8Y",
        "id": {
          "kind": "youtube#video",
          "videoId": "QWFdrsaF_TQ"
        },
        "snippet": {
          "publishedAt": "2023-12-29T10:28:36Z",
          "channelId": "UC4No3izdi6Mx8HowouUNzww",
          "title": "UW3 #31 - Untangling 2023: The Web3 Review | The Untangling Web3 Podcast",
          "description": "In this year-end review episode, the hosts recap the major themes and events of 2023 in the web3 space. They kick off by ...",
          "thumbnails": {
            "default": {
              "url": "https://i.ytimg.com/vi/QWFdrsaF_TQ/default.jpg",
              "width": 120,
              "height": 90
            },
            "medium": {
              "url": "https://i.ytimg.com/vi/QWFdrsaF_TQ/mqdefault.jpg",
              "width": 320,
              "height": 180
            },
            "high": {
              "url": "https://i.ytimg.com/vi/QWFdrsaF_TQ/hqdefault.jpg",
              "width": 480,
              "height": 360
            }
          },
          "channelTitle": "Untangling Web3",
          "liveBroadcastContent": "none",
          "publishTime": "2023-12-29T10:28:36Z"
        }
      },
      {
        "kind": "youtube#searchResult",
        "etag": "xZmRXhZm82-8Zd-KYFzGfb3aFYo",
        "id": {
          "kind": "youtube#video",
          "videoId": "znpq0g0LQn0"
        },
        "snippet": {
          "publishedAt": "2023-12-26T09:00:08Z",
          "channelId": "UC4No3izdi6Mx8HowouUNzww",
          "title": "UW3 #30 - Untangling: Binance & FTX | The Untangling Web3 Podcast",
          "description": "In this episode, the hosts delve into the recent upheavals in the Web3 finance sector, with a particular focus on the dramatic ...",
          "thumbnails": {
            "default": {
              "url": "https://i.ytimg.com/vi/znpq0g0LQn0/default.jpg",
              "width": 120,
              "height": 90
            },
            "medium": {
              "url": "https://i.ytimg.com/vi/znpq0g0LQn0/mqdefault.jpg",
              "width": 320,
              "height": 180
            },
            "high": {
              "url": "https://i.ytimg.com/vi/znpq0g0LQn0/hqdefault.jpg",
              "width": 480,
              "height": 360
            }
          },
          "channelTitle": "Untangling Web3",
          "liveBroadcastContent": "none",
          "publishTime": "2023-12-26T09:00:08Z"
        }
      },
      {
        "kind": "youtube#searchResult",
        "etag": "5RhH0lHajjgdQ1fDqVCAmTaV8ck",
        "id": {
          "kind": "youtube#video",
          "videoId": "6gcfpFgGMf0"
        },
        "snippet": {
          "publishedAt": "2023-12-25T12:00:09Z",
          "channelId": "UC4No3izdi6Mx8HowouUNzww",
          "title": "UW3 #29 - Untangling: The Crypto Markets with Gracy Chen | Untangling Web3",
          "description": "In this episode of Untangling Web3, hosts Alec Burns and Jack Davies welcome Gracy Chen, the managing director of Bitget, ...",
          "thumbnails": {
            "default": {
              "url": "https://i.ytimg.com/vi/6gcfpFgGMf0/default.jpg",
              "width": 120,
              "height": 90
            },
            "medium": {
              "url": "https://i.ytimg.com/vi/6gcfpFgGMf0/mqdefault.jpg",
              "width": 320,
              "height": 180
            },
            "high": {
              "url": "https://i.ytimg.com/vi/6gcfpFgGMf0/hqdefault.jpg",
              "width": 480,
              "height": 360
            }
          },
          "channelTitle": "Untangling Web3",
          "liveBroadcastContent": "none",
          "publishTime": "2023-12-25T12:00:09Z"
        }
      },
      {
        "kind": "youtube#searchResult",
        "etag": "13biXi0_5v_IelBkHKO0CMgL_Sw",
        "id": {
          "kind": "youtube#video",
          "videoId": "R_AuccIXIGo"
        },
        "snippet": {
          "publishedAt": "2023-12-24T16:00:04Z",
          "channelId": "UC4No3izdi6Mx8HowouUNzww",
          "title": "UW3 #28 - Untangling: DeFi | The Untangling Web3 Podcast",
          "description": "In this episode of Untangling Web3, hosts Alec Burns and Jack Davis delve into the complexities and innovations of decentralized ...",
          "thumbnails": {
            "default": {
              "url": "https://i.ytimg.com/vi/R_AuccIXIGo/default.jpg",
              "width": 120,
              "height": 90
            },
            "medium": {
              "url": "https://i.ytimg.com/vi/R_AuccIXIGo/mqdefault.jpg",
              "width": 320,
              "height": 180
            },
            "high": {
              "url": "https://i.ytimg.com/vi/R_AuccIXIGo/hqdefault.jpg",
              "width": 480,
              "height": 360
            }
          },
          "channelTitle": "Untangling Web3",
          "liveBroadcastContent": "none",
          "publishTime": "2023-12-24T16:00:04Z"
        }
      },
      {
        "kind": "youtube#searchResult",
        "etag": "zsteVa-Uk9BBtI111FR521k8Kvo",
        "id": {
          "kind": "youtube#video",
          "videoId": "H2xvknf_Kaw"
        },
        "snippet": {
          "publishedAt": "2023-12-22T13:07:37Z",
          "channelId": "UC4No3izdi6Mx8HowouUNzww",
          "title": "UW3 #27 - Untangling: TradFi | The Untangling Web3 Podcast",
          "description": "Love what you're hearing? Show your support by becoming a subscriber and don't forget to leave us a stellar review.",
          "thumbnails": {
            "default": {
              "url": "https://i.ytimg.com/vi/H2xvknf_Kaw/default.jpg",
              "width": 120,
              "height": 90
            },
            "medium": {
              "url": "https://i.ytimg.com/vi/H2xvknf_Kaw/mqdefault.jpg",
              "width": 320,
              "height": 180
            },
            "high": {
              "url": "https://i.ytimg.com/vi/H2xvknf_Kaw/hqdefault.jpg",
              "width": 480,
              "height": 360
            }
          },
          "channelTitle": "Untangling Web3",
          "liveBroadcastContent": "none",
          "publishTime": "2023-12-22T13:07:37Z"
        }
      },
      {
        "kind": "youtube#searchResult",
        "etag": "TVqr8HSfqaY6bYy6XT-tP98mVaY",
        "id": {
          "kind": "youtube#video",
          "videoId": "jLHaQWtbo8o"
        },
        "snippet": {
          "publishedAt": "2023-12-18T12:59:12Z",
          "channelId": "UC4No3izdi6Mx8HowouUNzww",
          "title": "UW3 #26 - Untangling: Digital Ownership & Access Controls with Sean Griffin | Untangling Web3",
          "description": "In this episode of Untangling Web3, hosts Alec Burns and Jack Davies engage with Sean Griffin, co-founder of Object Layer, ...",
          "thumbnails": {
            "default": {
              "url": "https://i.ytimg.com/vi/jLHaQWtbo8o/default.jpg",
              "width": 120,
              "height": 90
            },
            "medium": {
              "url": "https://i.ytimg.com/vi/jLHaQWtbo8o/mqdefault.jpg",
              "width": 320,
              "height": 180
            },
            "high": {
              "url": "https://i.ytimg.com/vi/jLHaQWtbo8o/hqdefault.jpg",
              "width": 480,
              "height": 360
            }
          },
          "channelTitle": "Untangling Web3",
          "liveBroadcastContent": "none",
          "publishTime": "2023-12-18T12:59:12Z"
        }
      },
      {
        "kind": "youtube#searchResult",
        "etag": "6V5GaYr8gTdvgJ1WQgSyqJaCQBI",
        "id": {
          "kind": "youtube#video",
          "videoId": "imQMT1uf2mg"
        },
        "snippet": {
          "publishedAt": "2023-12-15T15:01:16Z",
          "channelId": "UC4No3izdi6Mx8HowouUNzww",
          "title": "UW3 #25 - Untangling: CBDCs with John Velissarios (part 2) | Untangling Web3",
          "description": "In this episode of Untangling Web3, Alec Burns and Jack Davies delve further into central bank digital currencies (CBDCs) with ...",
          "thumbnails": {
            "default": {
              "url": "https://i.ytimg.com/vi/imQMT1uf2mg/default.jpg",
              "width": 120,
              "height": 90
            },
            "medium": {
              "url": "https://i.ytimg.com/vi/imQMT1uf2mg/mqdefault.jpg",
              "width": 320,
              "height": 180
            },
            "high": {
              "url": "https://i.ytimg.com/vi/imQMT1uf2mg/hqdefault.jpg",
              "width": 480,
              "height": 360
            }
          },
          "channelTitle": "Untangling Web3",
          "liveBroadcastContent": "none",
          "publishTime": "2023-12-15T15:01:16Z"
        }
      },
      {
        "kind": "youtube#searchResult",
        "etag": "GxEZLkZ-AGiKYedykRadzH85jE8",
        "id": {
          "kind": "youtube#video",
          "videoId": "wPYkFuA3e5A"
        },
        "snippet": {
          "publishedAt": "2023-12-11T10:30:15Z",
          "channelId": "UC4No3izdi6Mx8HowouUNzww",
          "title": "UW3 #24 - Untangling: CBDCs with John Velissarios (part 1) | Untangling Web3",
          "description": "In this week's episode hosts Alec Burns and Jack Davies are joined by John Velissarios, a seasoned expert in digital finance, ...",
          "thumbnails": {
            "default": {
              "url": "https://i.ytimg.com/vi/wPYkFuA3e5A/default.jpg",
              "width": 120,
              "height": 90
            },
            "medium": {
              "url": "https://i.ytimg.com/vi/wPYkFuA3e5A/mqdefault.jpg",
              "width": 320,
              "height": 180
            },
            "high": {
              "url": "https://i.ytimg.com/vi/wPYkFuA3e5A/hqdefault.jpg",
              "width": 480,
              "height": 360
            }
          },
          "channelTitle": "Untangling Web3",
          "liveBroadcastContent": "none",
          "publishTime": "2023-12-11T10:30:15Z"
        }
      },
      {
        "kind": "youtube#searchResult",
        "etag": "3fTwqFuBGtxfn_CgVfY8hcdrpdc",
        "id": {
          "kind": "youtube#video",
          "videoId": "yjY5TgrYwIg"
        },
        "snippet": {
          "publishedAt": "2023-12-08T12:31:19Z",
          "channelId": "UC4No3izdi6Mx8HowouUNzww",
          "title": "UW3 #23 - Untangling: BlackRock&#39;s Bitcoin ETF | The Untangling Web3 Podcast",
          "description": "In this episode, the focus is on BlackRock's anticipated Bitcoin ETF. Unlike other ETFs, BlackRock aims to own actual bitcoins.",
          "thumbnails": {
            "default": {
              "url": "https://i.ytimg.com/vi/yjY5TgrYwIg/default.jpg",
              "width": 120,
              "height": 90
            },
            "medium": {
              "url": "https://i.ytimg.com/vi/yjY5TgrYwIg/mqdefault.jpg",
              "width": 320,
              "height": 180
            },
            "high": {
              "url": "https://i.ytimg.com/vi/yjY5TgrYwIg/hqdefault.jpg",
              "width": 480,
              "height": 360
            }
          },
          "channelTitle": "Untangling Web3",
          "liveBroadcastContent": "none",
          "publishTime": "2023-12-08T12:31:19Z"
        }
      },
      {
        "kind": "youtube#searchResult",
        "etag": "ST-WNKhPr4hzq6YSMw-rj6UFDbU",
        "id": {
          "kind": "youtube#video",
          "videoId": "nKKccdghWzY"
        },
        "snippet": {
          "publishedAt": "2023-12-04T12:59:27Z",
          "channelId": "UC4No3izdi6Mx8HowouUNzww",
          "title": "UW3 #22 - Untangling: Digital Cash (part 2) | The Untangling Web3 Podcast",
          "description": "In this week's edition of Untangling Web3, hosts Alec Burns and Jack Davies dive into the world of digital cash, breaking down its ...",
          "thumbnails": {
            "default": {
              "url": "https://i.ytimg.com/vi/nKKccdghWzY/default.jpg",
              "width": 120,
              "height": 90
            },
            "medium": {
              "url": "https://i.ytimg.com/vi/nKKccdghWzY/mqdefault.jpg",
              "width": 320,
              "height": 180
            },
            "high": {
              "url": "https://i.ytimg.com/vi/nKKccdghWzY/hqdefault.jpg",
              "width": 480,
              "height": 360
            }
          },
          "channelTitle": "Untangling Web3",
          "liveBroadcastContent": "none",
          "publishTime": "2023-12-04T12:59:27Z"
        }
      },
      {
        "kind": "youtube#searchResult",
        "etag": "DfTZtDBsY_Gk_yhemQ1STMAbVi8",
        "id": {
          "kind": "youtube#video",
          "videoId": "J2NvqX2VUOA"
        },
        "snippet": {
          "publishedAt": "2023-12-01T13:34:17Z",
          "channelId": "UC4No3izdi6Mx8HowouUNzww",
          "title": "UW3 #21 - Untangling: Digital Cash (part 1) | The Untangling Web3 Podcast",
          "description": "In the latest episode of Untangling Web3, hosts Alec Burns and Jack Davies explore the concept of Digital Cash, one of the ...",
          "thumbnails": {
            "default": {
              "url": "https://i.ytimg.com/vi/J2NvqX2VUOA/default.jpg",
              "width": 120,
              "height": 90
            },
            "medium": {
              "url": "https://i.ytimg.com/vi/J2NvqX2VUOA/mqdefault.jpg",
              "width": 320,
              "height": 180
            },
            "high": {
              "url": "https://i.ytimg.com/vi/J2NvqX2VUOA/hqdefault.jpg",
              "width": 480,
              "height": 360
            }
          },
          "channelTitle": "Untangling Web3",
          "liveBroadcastContent": "none",
          "publishTime": "2023-12-01T13:34:17Z"
        }
      },
      {
        "kind": "youtube#searchResult",
        "etag": "6ikHpLb1VdTxMv84-KX6wW2O6UY",
        "id": {
          "kind": "youtube#video",
          "videoId": "C3oMIQMt2Eo"
        },
        "snippet": {
          "publishedAt": "2023-11-27T08:45:00Z",
          "channelId": "UC4No3izdi6Mx8HowouUNzww",
          "title": "UW3 #20 - Untangling: The Future of IoT with David Palmer | Untangling Web3",
          "description": "In the latest episode of Untangling Web3, hosts Alec Burns and Jack Davies, accompanied by David Palmer from Vodafone, delve ...",
          "thumbnails": {
            "default": {
              "url": "https://i.ytimg.com/vi/C3oMIQMt2Eo/default.jpg",
              "width": 120,
              "height": 90
            },
            "medium": {
              "url": "https://i.ytimg.com/vi/C3oMIQMt2Eo/mqdefault.jpg",
              "width": 320,
              "height": 180
            },
            "high": {
              "url": "https://i.ytimg.com/vi/C3oMIQMt2Eo/hqdefault.jpg",
              "width": 480,
              "height": 360
            }
          },
          "channelTitle": "Untangling Web3",
          "liveBroadcastContent": "none",
          "publishTime": "2023-11-27T08:45:00Z"
        }
      },
      {
        "kind": "youtube#searchResult",
        "etag": "WUpdMgX0vFXjTG-uQwCVLVvW_8g",
        "id": {
          "kind": "youtube#video",
          "videoId": "9LmXKHUOFS4"
        },
        "snippet": {
          "publishedAt": "2023-11-20T08:45:00Z",
          "channelId": "UC4No3izdi6Mx8HowouUNzww",
          "title": "UW3 #19 - Untangling: Decentralization | The Untangling Web3 Podcast",
          "description": "In this episode of Untangling Web3, Alec Burns and Jack Davies navigate through the intricate world of decentralization within ...",
          "thumbnails": {
            "default": {
              "url": "https://i.ytimg.com/vi/9LmXKHUOFS4/default.jpg",
              "width": 120,
              "height": 90
            },
            "medium": {
              "url": "https://i.ytimg.com/vi/9LmXKHUOFS4/mqdefault.jpg",
              "width": 320,
              "height": 180
            },
            "high": {
              "url": "https://i.ytimg.com/vi/9LmXKHUOFS4/hqdefault.jpg",
              "width": 480,
              "height": 360
            }
          },
          "channelTitle": "Untangling Web3",
          "liveBroadcastContent": "none",
          "publishTime": "2023-11-20T08:45:00Z"
        }
      },
      {
        "kind": "youtube#searchResult",
        "etag": "uKy1vojxGEScIad94Tk6ZubCWZs",
        "id": {
          "kind": "youtube#video",
          "videoId": "EHQHvJeLhkM"
        },
        "snippet": {
          "publishedAt": "2023-11-17T09:00:17Z",
          "channelId": "UC4No3izdi6Mx8HowouUNzww",
          "title": "UW3 #18 - Untangling: Crypto | The Untangling Web3 Podcast",
          "description": "In the latest instalment of Untangling Web3, hosts Alec and Jack dive deep into the buzzing world of crypto. Amidst a world that ...",
          "thumbnails": {
            "default": {
              "url": "https://i.ytimg.com/vi/EHQHvJeLhkM/default.jpg",
              "width": 120,
              "height": 90
            },
            "medium": {
              "url": "https://i.ytimg.com/vi/EHQHvJeLhkM/mqdefault.jpg",
              "width": 320,
              "height": 180
            },
            "high": {
              "url": "https://i.ytimg.com/vi/EHQHvJeLhkM/hqdefault.jpg",
              "width": 480,
              "height": 360
            }
          },
          "channelTitle": "Untangling Web3",
          "liveBroadcastContent": "none",
          "publishTime": "2023-11-17T09:00:17Z"
        }
      },
      {
        "kind": "youtube#searchResult",
        "etag": "k-kL0oNh4RdZOeYBQraAV3kseFg",
        "id": {
          "kind": "youtube#video",
          "videoId": "sLSPCBfsLJw"
        },
        "snippet": {
          "publishedAt": "2023-11-13T13:30:28Z",
          "channelId": "UC4No3izdi6Mx8HowouUNzww",
          "title": "UW3 #17 - Untangling: Personal Data Sovereignty with Heather Leigh-Flannery | Untangling Web3",
          "description": "Step into the riveting world of Untangling Web3 and meet Heather Leigh Flannery, the genius steering the ship at Equidium ...",
          "thumbnails": {
            "default": {
              "url": "https://i.ytimg.com/vi/sLSPCBfsLJw/default.jpg",
              "width": 120,
              "height": 90
            },
            "medium": {
              "url": "https://i.ytimg.com/vi/sLSPCBfsLJw/mqdefault.jpg",
              "width": 320,
              "height": 180
            },
            "high": {
              "url": "https://i.ytimg.com/vi/sLSPCBfsLJw/hqdefault.jpg",
              "width": 480,
              "height": 360
            }
          },
          "channelTitle": "Untangling Web3",
          "liveBroadcastContent": "none",
          "publishTime": "2023-11-13T13:30:28Z"
        }
      },
      {
        "kind": "youtube#searchResult",
        "etag": "fR_wHoTrkCzYg1LrgZmgQrE_KKs",
        "id": {
          "kind": "youtube#video",
          "videoId": "KEUgtlrUCyM"
        },
        "snippet": {
          "publishedAt": "2023-11-10T11:35:44Z",
          "channelId": "UC4No3izdi6Mx8HowouUNzww",
          "title": "UW3 #16 - Untangling: Digital Identity (part 2) | The Untangling Web3 Podcast",
          "description": "Join hosts Alec and Jack in the Untangling Web3 podcast as they delve deeper into the world of digital identity in their ...",
          "thumbnails": {
            "default": {
              "url": "https://i.ytimg.com/vi/KEUgtlrUCyM/default.jpg",
              "width": 120,
              "height": 90
            },
            "medium": {
              "url": "https://i.ytimg.com/vi/KEUgtlrUCyM/mqdefault.jpg",
              "width": 320,
              "height": 180
            },
            "high": {
              "url": "https://i.ytimg.com/vi/KEUgtlrUCyM/hqdefault.jpg",
              "width": 480,
              "height": 360
            }
          },
          "channelTitle": "Untangling Web3",
          "liveBroadcastContent": "none",
          "publishTime": "2023-11-10T11:35:44Z"
        }
      },
      {
        "kind": "youtube#searchResult",
        "etag": "FDRHYm2z7CyRQnr3QrQEwYIJHeM",
        "id": {
          "kind": "youtube#video",
          "videoId": "wduiwsVAAyE"
        },
        "snippet": {
          "publishedAt": "2023-11-06T07:42:08Z",
          "channelId": "UC4No3izdi6Mx8HowouUNzww",
          "title": "UW3 #15 - Untangling: Digital Identity (part 1) | The Untangling Web3 Podcast",
          "description": "In this episode, hosts Alec and Jack dive deep into the evolution of digital identity and its significance in Web3. The conversation ...",
          "thumbnails": {
            "default": {
              "url": "https://i.ytimg.com/vi/wduiwsVAAyE/default.jpg",
              "width": 120,
              "height": 90
            },
            "medium": {
              "url": "https://i.ytimg.com/vi/wduiwsVAAyE/mqdefault.jpg",
              "width": 320,
              "height": 180
            },
            "high": {
              "url": "https://i.ytimg.com/vi/wduiwsVAAyE/hqdefault.jpg",
              "width": 480,
              "height": 360
            }
          },
          "channelTitle": "Untangling Web3",
          "liveBroadcastContent": "none",
          "publishTime": "2023-11-06T07:42:08Z"
        }
      },
      {
        "kind": "youtube#searchResult",
        "etag": "e5mOF6KNd0rVqD-5U_W_KO2O6FM",
        "id": {
          "kind": "youtube#video",
          "videoId": "rn2MUd47YnE"
        },
        "snippet": {
          "publishedAt": "2023-10-30T08:00:31Z",
          "channelId": "UC4No3izdi6Mx8HowouUNzww",
          "title": "UW3 #14 - Untangling: Building the Metaverse | The Untangling Web3 Podcast",
          "description": "Join Alec Burns and Jack Davies on the Untangling Web3 podcast as they dive into the world of Web3 technologies with a special ...",
          "thumbnails": {
            "default": {
              "url": "https://i.ytimg.com/vi/rn2MUd47YnE/default.jpg",
              "width": 120,
              "height": 90
            },
            "medium": {
              "url": "https://i.ytimg.com/vi/rn2MUd47YnE/mqdefault.jpg",
              "width": 320,
              "height": 180
            },
            "high": {
              "url": "https://i.ytimg.com/vi/rn2MUd47YnE/hqdefault.jpg",
              "width": 480,
              "height": 360
            }
          },
          "channelTitle": "Untangling Web3",
          "liveBroadcastContent": "none",
          "publishTime": "2023-10-30T08:00:31Z"
        }
      },
      {
        "kind": "youtube#searchResult",
        "etag": "EY9iRRRvtw_XDXTaWj22ZvBa6MY",
        "id": {
          "kind": "youtube#video",
          "videoId": "q39iw6KSXa8"
        },
        "snippet": {
          "publishedAt": "2023-10-23T07:45:01Z",
          "channelId": "UC4No3izdi6Mx8HowouUNzww",
          "title": "UW3 #13 - Untangling: Web3 Venture Capital with Mona Tiesler | The Untangling Web3 Podcast",
          "description": "In this episode of Untangling Web3, hosts Alec Burns and Jack Davies sit down with Mona Tiesler, a venture capital investor with ...",
          "thumbnails": {
            "default": {
              "url": "https://i.ytimg.com/vi/q39iw6KSXa8/default.jpg",
              "width": 120,
              "height": 90
            },
            "medium": {
              "url": "https://i.ytimg.com/vi/q39iw6KSXa8/mqdefault.jpg",
              "width": 320,
              "height": 180
            },
            "high": {
              "url": "https://i.ytimg.com/vi/q39iw6KSXa8/hqdefault.jpg",
              "width": 480,
              "height": 360
            }
          },
          "channelTitle": "Untangling Web3",
          "liveBroadcastContent": "none",
          "publishTime": "2023-10-23T07:45:01Z"
        }
      },
      {
        "kind": "youtube#searchResult",
        "etag": "s5494nammpaCPca8mh2WRFlmttU",
        "id": {
          "kind": "youtube#video",
          "videoId": "-wwISro8oHw"
        },
        "snippet": {
          "publishedAt": "2023-10-16T07:30:10Z",
          "channelId": "UC4No3izdi6Mx8HowouUNzww",
          "title": "UW3 #12 - Untangling: Internet of Things | The Untangling Web3 Podcast",
          "description": "In this episode of Untangling Web3, Alec and Jack unravel the captivating fusion of Web Three and the Internet of Things (IoT).",
          "thumbnails": {
            "default": {
              "url": "https://i.ytimg.com/vi/-wwISro8oHw/default.jpg",
              "width": 120,
              "height": 90
            },
            "medium": {
              "url": "https://i.ytimg.com/vi/-wwISro8oHw/mqdefault.jpg",
              "width": 320,
              "height": 180
            },
            "high": {
              "url": "https://i.ytimg.com/vi/-wwISro8oHw/hqdefault.jpg",
              "width": 480,
              "height": 360
            }
          },
          "channelTitle": "Untangling Web3",
          "liveBroadcastContent": "none",
          "publishTime": "2023-10-16T07:30:10Z"
        }
      },
      {
        "kind": "youtube#searchResult",
        "etag": "3eUH9wa_dIlCZdimVYHul_Yy__0",
        "id": {
          "kind": "youtube#video",
          "videoId": "OYVi9MWqrBw"
        },
        "snippet": {
          "publishedAt": "2023-10-13T10:09:15Z",
          "channelId": "UC4No3izdi6Mx8HowouUNzww",
          "title": "UW3 #11 - Untangling: Generative AI with Colin Fitzpatrick | The Untangling Web3 Podcast",
          "description": "Join Alec and Jack in this engaging episode of Untangling Web3 as they dive into the world of Generative AI with a focus on its ...",
          "thumbnails": {
            "default": {
              "url": "https://i.ytimg.com/vi/OYVi9MWqrBw/default.jpg",
              "width": 120,
              "height": 90
            },
            "medium": {
              "url": "https://i.ytimg.com/vi/OYVi9MWqrBw/mqdefault.jpg",
              "width": 320,
              "height": 180
            },
            "high": {
              "url": "https://i.ytimg.com/vi/OYVi9MWqrBw/hqdefault.jpg",
              "width": 480,
              "height": 360
            }
          },
          "channelTitle": "Untangling Web3",
          "liveBroadcastContent": "none",
          "publishTime": "2023-10-13T10:09:15Z"
        }
      },
      {
        "kind": "youtube#searchResult",
        "etag": "KAbGWJ0eMjYfsWNvqMADAMFxozQ",
        "id": {
          "kind": "youtube#video",
          "videoId": "0MKHaCkevVI"
        },
        "snippet": {
          "publishedAt": "2023-10-09T08:00:06Z",
          "channelId": "UC4No3izdi6Mx8HowouUNzww",
          "title": "UW3 #10 - Untangling: Artificial Intelligence (part 2) | The Untangling Web3 Podcast",
          "description": "Welcome back to the second part of the two-part series of Untangling AI episodes, where hosts Alec and Jack continue their ...",
          "thumbnails": {
            "default": {
              "url": "https://i.ytimg.com/vi/0MKHaCkevVI/default.jpg",
              "width": 120,
              "height": 90
            },
            "medium": {
              "url": "https://i.ytimg.com/vi/0MKHaCkevVI/mqdefault.jpg",
              "width": 320,
              "height": 180
            },
            "high": {
              "url": "https://i.ytimg.com/vi/0MKHaCkevVI/hqdefault.jpg",
              "width": 480,
              "height": 360
            }
          },
          "channelTitle": "Untangling Web3",
          "liveBroadcastContent": "none",
          "publishTime": "2023-10-09T08:00:06Z"
        }
      },
      {
        "kind": "youtube#searchResult",
        "etag": "oFxlUvHRCj7IAeSoOO8dvN-E2y8",
        "id": {
          "kind": "youtube#video",
          "videoId": "UnzVSMTyxqk"
        },
        "snippet": {
          "publishedAt": "2023-10-06T13:58:44Z",
          "channelId": "UC4No3izdi6Mx8HowouUNzww",
          "title": "UW3 #9 - Untangling: Artificial Intelligence (part 1) | The Untangling Web3 Podcast",
          "description": "Join hosts Alec and Jack on the Untangling Web3 podcast as they embark on a thrilling exploration of Artificial Intelligence (AI) ...",
          "thumbnails": {
            "default": {
              "url": "https://i.ytimg.com/vi/UnzVSMTyxqk/default.jpg",
              "width": 120,
              "height": 90
            },
            "medium": {
              "url": "https://i.ytimg.com/vi/UnzVSMTyxqk/mqdefault.jpg",
              "width": 320,
              "height": 180
            },
            "high": {
              "url": "https://i.ytimg.com/vi/UnzVSMTyxqk/hqdefault.jpg",
              "width": 480,
              "height": 360
            }
          },
          "channelTitle": "Untangling Web3",
          "liveBroadcastContent": "none",
          "publishTime": "2023-10-06T13:58:44Z"
        }
      },
      {
        "kind": "youtube#searchResult",
        "etag": "jkxv7ZZCmt3pc7vlS2EDYVv_EQk",
        "id": {
          "kind": "youtube#video",
          "videoId": "LtrKxuVRjlI"
        },
        "snippet": {
          "publishedAt": "2023-10-02T07:55:08Z",
          "channelId": "UC4No3izdi6Mx8HowouUNzww",
          "title": "UW3 #8 - Untangling: Web3 Safety & QA with Rafaela Azevedo | The Untangling Web3 Podcast",
          "description": "Join hosts Alec and Jack on the Untangling Web3 podcast as they dive into the fascinating world of Web3 safety and quality ...",
          "thumbnails": {
            "default": {
              "url": "https://i.ytimg.com/vi/LtrKxuVRjlI/default.jpg",
              "width": 120,
              "height": 90
            },
            "medium": {
              "url": "https://i.ytimg.com/vi/LtrKxuVRjlI/mqdefault.jpg",
              "width": 320,
              "height": 180
            },
            "high": {
              "url": "https://i.ytimg.com/vi/LtrKxuVRjlI/hqdefault.jpg",
              "width": 480,
              "height": 360
            }
          },
          "channelTitle": "Untangling Web3",
          "liveBroadcastContent": "none",
          "publishTime": "2023-10-02T07:55:08Z"
        }
      },
      {
        "kind": "youtube#searchResult",
        "etag": "uCnS8Itvug-B1kmTPjIfGZqRsJw",
        "id": {
          "kind": "youtube#video",
          "videoId": "IhnnzvdgC38"
        },
        "snippet": {
          "publishedAt": "2023-09-29T10:23:34Z",
          "channelId": "UC4No3izdi6Mx8HowouUNzww",
          "title": "UW3 #7 - Untangling: Data Sovereignty | The Untangling Web3 Podcast",
          "description": "Join hosts Alec and Jack on the Untangling Web3 podcast as they explore the captivating concept of data sovereignty and ...",
          "thumbnails": {
            "default": {
              "url": "https://i.ytimg.com/vi/IhnnzvdgC38/default.jpg",
              "width": 120,
              "height": 90
            },
            "medium": {
              "url": "https://i.ytimg.com/vi/IhnnzvdgC38/mqdefault.jpg",
              "width": 320,
              "height": 180
            },
            "high": {
              "url": "https://i.ytimg.com/vi/IhnnzvdgC38/hqdefault.jpg",
              "width": 480,
              "height": 360
            }
          },
          "channelTitle": "Untangling Web3",
          "liveBroadcastContent": "none",
          "publishTime": "2023-09-29T10:23:34Z"
        }
      },
      {
        "kind": "youtube#searchResult",
        "etag": "o1OysxxHc7GddwuvA8znVoreXqg",
        "id": {
          "kind": "youtube#video",
          "videoId": "xhGsQfbYzco"
        },
        "snippet": {
          "publishedAt": "2023-09-25T08:20:08Z",
          "channelId": "UC4No3izdi6Mx8HowouUNzww",
          "title": "UW3 #6 - Untangling: XR & Metaverse with Robert Rice | The Untangling Web3 Podcast",
          "description": "Join hosts Alec and Jack on the Untangling Web3 podcast as they dive into the captivating world of Web3 and immersive ...",
          "thumbnails": {
            "default": {
              "url": "https://i.ytimg.com/vi/xhGsQfbYzco/default.jpg",
              "width": 120,
              "height": 90
            },
            "medium": {
              "url": "https://i.ytimg.com/vi/xhGsQfbYzco/mqdefault.jpg",
              "width": 320,
              "height": 180
            },
            "high": {
              "url": "https://i.ytimg.com/vi/xhGsQfbYzco/hqdefault.jpg",
              "width": 480,
              "height": 360
            }
          },
          "channelTitle": "Untangling Web3",
          "liveBroadcastContent": "none",
          "publishTime": "2023-09-25T08:20:08Z"
        }
      },
      {
        "kind": "youtube#searchResult",
        "etag": "kSsy5YCihcbYL1rxHJFw_VKOEgM",
        "id": {
          "kind": "youtube#video",
          "videoId": "JojF1yowo1c"
        },
        "snippet": {
          "publishedAt": "2023-09-22T08:15:10Z",
          "channelId": "UC4No3izdi6Mx8HowouUNzww",
          "title": "UW3 #5 - Untangling: Metaverse | The Untangling Web3 Podcast",
          "description": "Welcome to another exciting episode of Untangling Web3 with your hosts Alec and Jack! In this episode, they delve into the ...",
          "thumbnails": {
            "default": {
              "url": "https://i.ytimg.com/vi/JojF1yowo1c/default.jpg",
              "width": 120,
              "height": 90
            },
            "medium": {
              "url": "https://i.ytimg.com/vi/JojF1yowo1c/mqdefault.jpg",
              "width": 320,
              "height": 180
            },
            "high": {
              "url": "https://i.ytimg.com/vi/JojF1yowo1c/hqdefault.jpg",
              "width": 480,
              "height": 360
            }
          },
          "channelTitle": "Untangling Web3",
          "liveBroadcastContent": "none",
          "publishTime": "2023-09-22T08:15:10Z"
        }
      },
      {
        "kind": "youtube#searchResult",
        "etag": "4vQfM94qSALChdWM9ixqMo0Nh90",
        "id": {
          "kind": "youtube#video",
          "videoId": "TWfGfNWhcqk"
        },
        "snippet": {
          "publishedAt": "2023-09-18T07:30:08Z",
          "channelId": "UC4No3izdi6Mx8HowouUNzww",
          "title": "UW3 #4 - Untangling: Micropayments | The Untangling Web3 Podcast",
          "description": "Join hosts Alec and Jack on an exciting episode of Untangling Web3 as they explore the world of micro-payments and their ...",
          "thumbnails": {
            "default": {
              "url": "https://i.ytimg.com/vi/TWfGfNWhcqk/default.jpg",
              "width": 120,
              "height": 90
            },
            "medium": {
              "url": "https://i.ytimg.com/vi/TWfGfNWhcqk/mqdefault.jpg",
              "width": 320,
              "height": 180
            },
            "high": {
              "url": "https://i.ytimg.com/vi/TWfGfNWhcqk/hqdefault.jpg",
              "width": 480,
              "height": 360
            }
          },
          "channelTitle": "Untangling Web3",
          "liveBroadcastContent": "none",
          "publishTime": "2023-09-18T07:30:08Z"
        }
      },
      {
        "kind": "youtube#searchResult",
        "etag": "QMeXN-T7J7XCtINcVqyLR3oyujY",
        "id": {
          "kind": "youtube#video",
          "videoId": "x6kGvNXJ66A"
        },
        "snippet": {
          "publishedAt": "2023-09-15T10:31:20Z",
          "channelId": "UC4No3izdi6Mx8HowouUNzww",
          "title": "UW3 #3 - Untangling: Tokens | The Untangling Web3 Podcast",
          "description": "Web3 is on the cusp of a revolution, with tokenization primed to transform the landscape of asset representation. With predictions ...",
          "thumbnails": {
            "default": {
              "url": "https://i.ytimg.com/vi/x6kGvNXJ66A/default.jpg",
              "width": 120,
              "height": 90
            },
            "medium": {
              "url": "https://i.ytimg.com/vi/x6kGvNXJ66A/mqdefault.jpg",
              "width": 320,
              "height": 180
            },
            "high": {
              "url": "https://i.ytimg.com/vi/x6kGvNXJ66A/hqdefault.jpg",
              "width": 480,
              "height": 360
            }
          },
          "channelTitle": "Untangling Web3",
          "liveBroadcastContent": "none",
          "publishTime": "2023-09-15T10:31:20Z"
        }
      },
      {
        "kind": "youtube#searchResult",
        "etag": "IWjkAjfAHc6jsRGBoiqs7uQ-cW0",
        "id": {
          "kind": "youtube#video",
          "videoId": "UW3jFV5RoKQ"
        },
        "snippet": {
          "publishedAt": "2023-09-11T11:45:15Z",
          "channelId": "UC4No3izdi6Mx8HowouUNzww",
          "title": "UW3 #2 - Untangling: Blockchain | The Untangling Web3 Podcast",
          "description": "When you hear the term 'blockchain' you might assume we're referring to 'crypto'. But that's not always the case. With so much ...",
          "thumbnails": {
            "default": {
              "url": "https://i.ytimg.com/vi/UW3jFV5RoKQ/default.jpg",
              "width": 120,
              "height": 90
            },
            "medium": {
              "url": "https://i.ytimg.com/vi/UW3jFV5RoKQ/mqdefault.jpg",
              "width": 320,
              "height": 180
            },
            "high": {
              "url": "https://i.ytimg.com/vi/UW3jFV5RoKQ/hqdefault.jpg",
              "width": 480,
              "height": 360
            }
          },
          "channelTitle": "Untangling Web3",
          "liveBroadcastContent": "none",
          "publishTime": "2023-09-11T11:45:15Z"
        }
      },
      {
        "kind": "youtube#searchResult",
        "etag": "M8RRSWb_5ZQxxvUahGuSrR6Xteo",
        "id": {
          "kind": "youtube#video",
          "videoId": "vMzHXiicG9o"
        },
        "snippet": {
          "publishedAt": "2023-09-08T12:16:25Z",
          "channelId": "UC4No3izdi6Mx8HowouUNzww",
          "title": "UW3 #1 - Untangling: Web3 | The Untangling Web3 Podcast",
          "description": "Navigating your way through the age of Web3, blockchain, and AI can be confusing. With technology that seems to advance each ...",
          "thumbnails": {
            "default": {
              "url": "https://i.ytimg.com/vi/vMzHXiicG9o/default.jpg",
              "width": 120,
              "height": 90
            },
            "medium": {
              "url": "https://i.ytimg.com/vi/vMzHXiicG9o/mqdefault.jpg",
              "width": 320,
              "height": 180
            },
            "high": {
              "url": "https://i.ytimg.com/vi/vMzHXiicG9o/hqdefault.jpg",
              "width": 480,
              "height": 360
            }
          },
          "channelTitle": "Untangling Web3",
          "liveBroadcastContent": "none",
          "publishTime": "2023-09-08T12:16:25Z"
        }
      }
      
    ]
  }]
export default infoAlt;