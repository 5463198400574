import{BrowserRouter,Routes,Route} from 'react-router-dom';
// import App from '../App.js';
import Home from '../pages/Home/Home.js';
import EpisodesPage from '../pages/Episodes';
import Blog from '../pages/Blog'
import About from '../pages/About/index.js';

export default function Router(){
    return(
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Home/>}/>
                <Route path='/episodes' element={<EpisodesPage />}/>
                <Route path='/blog' element={<Blog />}/>
                <Route path='/about' element={<About />}/>
            </Routes>
        </BrowserRouter>
    )
}