import './Nav.css'
import {  useState } from "react";
import{Link} from 'react-router-dom';

import Subscribe from "../../components/Subscribe";
export default function Nav({reference}) {

    const[hiddenModal,setHiddenModal]=useState(true);
    let [classMenu,setclassMenu]=useState('hideMenu');
    let [counter,setCounter]=useState(false);

    const showMenu =()=>{
        if(counter === false){
            setclassMenu("d-flex")
        }else{
            setclassMenu("hideMenu")
        }
        setCounter(!counter)
        
    }

    return(
        <nav className='navegation container-fluid border-bottom' >
        {hiddenModal === false ?<Subscribe setHiddenModal={setHiddenModal} /> :''}

        <div className='row w-100 justify-content-between ' >
            <Link className='col-md-3 m-0 img-logo d-flex align-items-center' to='/'>
                <img className='w-100' src='./images/logo.svg' alt='logo'/>
            </Link>
            <div className='d-flex menu-section col-md-11 justify-content-end px-0'>
                <img onClick={()=>{ showMenu()}} src='./images/list.svg' alt='menu hamburguesa' className='pe-2 menuHamb'/>
                <ul className={'z-0 menu '+classMenu}>
                    <li className={ reference ==="episodes"?"nav-item border-bottom-item":"nav-item"}> 
                        <Link className='text-decoration-none btn-nav' to='/episodes'>ALL EPISODES</Link>
                    </li>
                    <li className={reference ==="blog"?"nav-item border-bottom-item":"nav-item"}>
                        <Link className='text-decoration-none btn-nav' to='/blog'>BLOG</Link>
                    </li>
                    <li className={reference ==="about"?"nav-item border-bottom-item":"nav-item"}>
                        <Link className='text-decoration-none btn-nav' to='/about'>ABOUT US</Link>
                    </li>
                </ul>   
                <button className='button-subscribe border-start bg-transparent nav-item border-0 btn-nav' onClick={()=>setHiddenModal(false)}>SUBSCRIBE</button>
            </div>
     
        </div>

        </nav>
    )
}