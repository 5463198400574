import { Fragment ,useState} from "react";

import Nav from '../../components/Nav';
import Listening from '../../components/Listening';
import Host from '../../components/Host';
import ThanksItem from '../../components/ThanksItem';

import hosts from "../../info/infoHost.js"
import thanksList from "../../info/infoThanks.js"

import './About.css';

export default function About() {
    let [hiddenModal,setHiddenModal]=useState(true);
    const [hostData]=useState(hosts)
    const [thanksItem]=useState(thanksList)

    return(
        <Fragment>
            { hiddenModal === false ? <Listening setHiddenModal={setHiddenModal} /> :''}
            <img className="position-fixed start-0 img-secondary" src="./bg-secondary.png" alt="" aria-hidden="true"/>     <Nav />
            <img className="w-100 position-absolute start-0 img-bg-mobile" src="./bg-mobile.png" alt="" aria-hidden="true"/>

            <div className="margin-mb">
                <div className="about-section-info">
                    <img className="team-image" src="./images/about-img/untangling-team.png" alt="untangling team"/>
                    <div className="about-section-text">
                        <h1>About us</h1>
                        <p>Welcome to the Untangling Web3 podcast. Your go-to hub to learn insights and the latest developments in the wild and wonderful world of Web3.</p>
                        <p>Navigating your way through the age of Web3, blockchain, and AI can be confusing. That’s why we simplify the big ideas, events, and technologies in Web3 to make them accessible to everyone.</p>
                        <p>But we don’t do it alone. We also interview the leading industry experts and thought leaders in the Web3 space to demystify all the nuances of the tech and applications that define Web3.</p>
                        <ul className="d-flex host-list ps-0">
                            <li ><a className="link-host" href="#alec">Alec</a></li>
                            <li ><a className="link-host" href="#jack">Jack</a></li>
                        </ul>                
                    </div>                
                </div>


                {hostData.map((person)=>{
                    return <Host key={person.id} image={person.image} nameHost={person.nameHost} textHost={person.textHost} linkedinHost={person.linkedinHost} orientation={person.orientation} classHost={person.classHost} idHost={person.idHost} textOrientatio={person.textOrientatio}/>})}    

            </div>
            <footer className="border-top footer-about">
                <h4 className="title-footer">WE WANT TO THANK</h4>
                {thanksItem.map((item)=>{
                    return <ThanksItem key={item.id} info={item}/>
                })}
            </footer>
        </Fragment>
    )
}