import './Hero.css';

export default function Hero() {
    return(
        <header id='intro' className='padding-mobile header container-fluid vh-100 d-flex flex-column  gap-disc' >
        <div className='row align-items-center header-area'>
            <div className=' col-lg-5 col-xxl-4  align-self-start justify-content-start justify-content-xl-center pe-xl-6 justify-content-xxl-start d-flex  logo-container'>
                <img className='logo-hero align-self-lg-center ' src='./images/logo.svg' alt='logo'/>
            </div>
            <div className=' col-lg-9 hero-desc-and-title'>
                <h1 >Untangling Web3:</h1> 
                <h2 className='hero-subtitle'>The podcast that simplifies tomorrow's tech, today.</h2>
                <p className='desc-header'>Join us as we break down the complex world of Web3, equipping you with everything you need to thrive in the emerging landscape of digital, decentralized, and disruptive technologies that are changing the world.</p>
                <a href="#episodes" className='row button-header text-uppercase text-center text-decoration-none'>start listening</a>            
            </div>        
        </div>   
        <div className='row'>
            <div className='d-flex align-items-center section-disc' >
                <img className='animation-disc' src="./images/disc.png" alt='disc'/>
                <p className='text-uppercase scroll-down'>{ '>> scroll down'}</p>                
            </div>
        </div>

        </header>
    )
} 