import { useEffect ,useState } from "react";

import CardEpisodes from "../CardEpisodeHome";
//import SearchComponente from "../SearchComponent";
import infoAlt from "../../info/infoep";


import './Episode.css';

export default function EpisodesHome({setHiddenModal,setDataEp}) {
      let counter=0; 
      let [limit,setLimit]=useState(10)
      let [epRep,setEpRep]=useState([...infoAlt[0].items]);
      let [infoEp,setInfoEp]=useState([...infoAlt[0].items]);
      let [inputS,setInputS]=useState("");
      let key="AIzaSyC7s3cKGn0gllolBc37EAo6Ke-hWOZVeAw";


      const bringEpisodes=async()=>{
        
         let info= await fetch( "https://www.googleapis.com/youtube/v3/search?key="+key+"&channelId=UC4No3izdi6Mx8HowouUNzww&part=snippet,id&order=date&maxResults=100")
                        .then(respuesta => respuesta.json())
                        .catch(error => console.log("HAY UN ERROR!!" +error))
         
         return info
         
      }
 

      const addEpisodes=(number)=>{
         let newLimit= number+10;
         console.log(newLimit)
         setLimit(newLimit)
      }

      const handlerSearch=(event)=>{
        event.preventDefault()
      }
      const handlerSearchInput=(event)=>{
        let searchInput=event.target.value.toLowerCase();
        
        setInputS(searchInput);
        const searchEp= epRep.filter((pal)=>{ let word=pal.snippet.title.toLowerCase();return word.includes(searchInput) })
       
        if(searchEp.length >0){
             setInfoEp(searchEp)
        } 
   
        
      }



       useEffect(()=>{   
         const showInfo =async ()=>{
            let data= await bringEpisodes();

            //if we get to the limit of the calls to the API per day
            if(data.error){
                localStorage.setItem("data",JSON.stringify(infoAlt[0].items))
            }else{
               
                //if we obtain the info from the API
                let dataFilter=data.items.filter((pod)=>{return pod.etag !== "4v1u0iax1UPmXUzcxCrZyB2Agdw"});
                
                setInfoEp(dataFilter);
                setEpRep(dataFilter)
                localStorage.setItem("data",JSON.stringify(dataFilter))
            }
            
         }
         showInfo() ;
      
      },[])
    
    return(
        <section className="container-fluid border-top " id="episodes" >
            <div className="row row-episode align-items-center justify-content-between">
               <h2 className="col-lg-6  title-ep text-uppercase text-start">Episodes</h2>
              { // <SearchComponente title="Episodes" handleSearchInput={handlerSearchInput}/>
            }
               <form className="col-lg-6 d-flex position-relative form-ep" role="search" onSubmit={(event)=>handlerSearch(event)}>
                     <input className="form-control input-search me-2 bg-transparent" type="search" aria-label="Search" id="searchInput" onKeyUp={(event)=>handlerSearchInput(event)} onInput={(event)=>handlerSearchInput(event)}/>
                     <button className="btn position-absolute z-1 top-glass p-0" type="submit"><img src="./images/m-glass.svg" alt="search button"/></button>
               </form> 
                 
            </div>

            <section className="row flex-column align-items-center gap-4 ">
           {infoEp.map((ep)=>{
                counter++
                  return <CardEpisodes key={counter} setHiddenModal={setHiddenModal} data={ep} cant={counter} limit={limit} setDataEp={setDataEp} cantEp={inputS}/>
               })} 
         
              
               {infoEp.length >limit? <button className="d-flex justify-content-center space-button-load align-items-center button-load-ep text-uppercase text-center" onClick={()=>addEpisodes(limit)}>load 10 more</button>: ''}
            </section>

        </section>
    )
}

