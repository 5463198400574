import './Thanks.css'

export default function ThanksItem({info}) {
    return(
        <div>
            <h5 className="name-thanks thanks">{info.name}</h5>
            <p className='text-thanks thanks'>{info.message}</p>
            <div className='d-flex justify-content-center  media-section-thanks'>
            <a href={info.linkedin} target='__blank'>
                <img className=' media-icon-thanks' src="./images/about-img/linkedin-about.svg" alt="linkedin icon"/>            
            </a>

            </div>
            
        
        </div>
    )
}