
import './Host.css'


export default function Host({image,nameHost,textHost,linkedinHost,orientation,classHost,idHost,textOrientatio}) {
    return(
        <div className={'host '+ classHost} id={idHost}>
            
            <div className='host-area'>
                <h3 className="hostName">{nameHost}</h3> 
                <p className={'hostText '+textOrientatio}>{textHost}</p>
                <a href={linkedinHost} className={"d-flex w-100 "+orientation}><img src='./images/about-img/linkedin-about.svg' alt="linkedin icon"/></a>
            </div>
            <img className='image-host rounded' src={image} alt={nameHost} />
        </div>
    )
}