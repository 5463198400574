import './ResumeArticle.css'

export default function Resume_Article() {
    return(
        <article className="bg-black d-flex flex-column-reverse  padding-card rounded-4 resume-article">
            <div className='info-article'>
                <h3 className='title-article'>Blog title example and others stuff and blab...</h3>
                <p>
                    <span className='date-time-article fw-semibold'>24th October, 2023. 14:30hs</span>
                    .
                    <span className='date-time-article fw-medium'>5 min  read </span>
                </p>
                <p className='article-description'>
                    Join the digital revolution and never miss an episode of "Untangling Web3" by subscribing to our podcast with your email. We break down the complex world of Web3 into digestible episodes, providing clear insights and crucial information to help you stay ahead. Join the digital revolution and never miss an episode of "Untangling Web3" by subscribing to our podcast with your email.
                </p>        
            </div>
        <img src="./images/blog-article/Rectangle.png" alt=""/>
             
        </article>
    )
}