import { Fragment , useState} from "react";

import Nav from "../../components/Nav";
import SearchComponente from '../../components/SearchComponent';
import ResumeArticle from'../../components/ResumeArticle';

import './Blog.css'

export default function Blog() {

    const[hideSection]=useState(true)

    return(
        <Fragment>
        <img className="position-fixed start-0 img-secondary" src="./bg-secondary.png" alt="" aria-hidden="true"/>     <Nav reference="blog"/>
        <img className="w-100 position-absolute start-0 img-bg-mobile" src="./bg-mobile.png" alt="" aria-hidden="true"/>
   
        {hideSection?<h2 className="title-comming text-center "> Coming soon</h2>
                :<Fragment>
                <section className="search-blog">
                    <SearchComponente title="blog"/>
                </section>
                <section className="d-flex flex-column align-items-center gap-2">
                    <ResumeArticle/>
                    <ResumeArticle/>
                    <ResumeArticle/>
                </section>
            </Fragment>}
        </Fragment>
    )
}